export const CSCoreSpring2019TOC = [
  { youthName: 'Coral', slug: '/courses/cs-core/2019spring/coral' },
  { youthName: 'Ezekiel', slug: '/courses/cs-core/2019spring/ezekiel' },
  { youthName: 'Jade', slug: '/courses/cs-core/2019spring/jade' },
  { youthName: 'Jeshua', slug: '/courses/cs-core/2019spring/jeshua' },
  { youthName: 'McKenna', slug: '/courses/cs-core/2019spring/mckenna' },
  {
    youthName: 'Mitchell',
    slug: '/courses/cs-core/2019spring/mitchell',
  },
  { youthName: 'Stephen', slug: '/courses/cs-core/2019spring/coral' },
];
